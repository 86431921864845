<template>
  <div class="d-flex full-width">
    <copy-icon
      v-if="copyable"
      :data="text"
    ></copy-icon>

    <v-tooltip
      top
      :disabled="!activeTooltip"
    >
      <template v-slot:activator="{on}">
        <div class="full-width">
          <div
            v-if="!isHtml"
            v-on="on"
            class="full-width cursor-pointer"
            :class="textClass"
          >
            {{text}}
          </div>

          <html-content
            v-if="isHtml"
            :on="on"
            class="pt-4 cursor-pointer"
            :value="text"
          ></html-content>
        </div>
      </template>
      <span v-html="tooltipContent"></span>
    </v-tooltip>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  computed: {
    textClass() {
      let textClass = []
      if(this.config.truncate != false) {
        textClass.push('text-truncate')
      }
      return textClass
    },
    tooltipContent() {
      if(typeof this.config.tooltipContent === 'function') {
        return this.config.tooltipContent(this.row)
      }
      return this.text
    },
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>
